<template>
    <v-menu offset-y left>
        <template v-slot:activator="{ on }">
            <v-btn class="intro-account grey--text text--darken-1" style="font-size: 10px" text v-on="on">
                <v-icon left> fas fa-user-circle </v-icon>
                {{ $store.state.application.user.name.slice(0,15) }}
                <template v-if="$store.state.application.user.name.length > 15">
                    ...
                </template>
                <v-icon right> fas fa-caret-down </v-icon>
            </v-btn>
        </template>

        <v-list class="py-0">
            <v-list-item class="py-1 grey lighten-3">
                <v-list-item-avatar class="white--text text-uppercase headline" :color="getColorByName(nameFirstLetter)">
                    {{ nameFirstLetter }}
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title class="title"> {{ $store.state.application.user.name }} </v-list-item-title>
                    <v-list-item-subtitle> {{ $store.state.application.user.email }} </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider />

            <GuidedTourDialog>
                <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">
                        <v-list-item-action>
                            <v-icon small> fas fa-question-circle </v-icon>
                        </v-list-item-action>

                        <v-list-item-title> Visite guidée </v-list-item-title>
                    </v-list-item>
                </template>
            </GuidedTourDialog>

            <DebugAccounts v-if="$store.state.application.user.type === 'user'">
                <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">
                        <v-list-item-action>
                            <v-icon small> fas fa-user-shield </v-icon>
                        </v-list-item-action>

                        <v-list-item-title> Connexions </v-list-item-title>
                    </v-list-item>
                </template>
            </DebugAccounts>  

            <v-list-item @click="disconnect()">
                <v-list-item-action>
                    <v-icon color="red" small> fas fa-sign-out-alt </v-icon>
                </v-list-item-action>

                <v-list-item-title> Déconnexion </v-list-item-title>
            </v-list-item>

            <v-divider />

            <v-list-item class="grey lighten-3" dense>
                <v-list-item-action>
                    <v-icon small> fas fa-code-branch </v-icon>
                </v-list-item-action>

                <v-list-item-title> v{{ $store.state.application.version }} </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import Vue from 'vue';
import DebugAccounts from '../debug/DebugAccounts.vue';
import GuidedTourDialog from '../dialogs/GuidedTourDialog.vue';

export default {
    name: 'UserProfile',

    components: {
        DebugAccounts,
        GuidedTourDialog
    },

    methods: {
        async disconnect() {
            try {
                const { success } = await this.repos.auth.disconnect();
                if (success) {
                    if (this.$store.state.application.user.type === 'buyer') {
                        this.$router.push('/connexion');
                    } else {
                        this.$router.push('/connexion/utilisateur');
                    }
                }
            } catch (err) {
                Vue.notify({
                    type: 'error',
                    title: 'Erreur',
                    text: 'Une erreur est survenue durant la deconnexion',
                    duration: 10000
                });
            }
        }
    },

    computed: {
        nameFirstLetter() {
            return this.$store.state.application.user.name.slice(0, 1);
        }
    }
};
</script>
